//author: Alisson Ross
import Team from './Team.js'
import AboutHackMerced from './AboutHackMerced.js';
export default function AboutUs(){
    return(
        <div>
            <AboutHackMerced/>
            <Team/>
        </div>
    );
}